import "./App.css";
import React, { useEffect, useState } from "react";

import jsonData from "./resume.json";
import Markdown from "react-markdown";
import { Icon } from "@iconify/react";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const resume = JSON.parse(JSON.stringify(jsonData));

const firebaseConfig = {
  apiKey: "AIzaSyCccH5J7Fq3ml4ECtgg0avD_-duhVsC1Xk",
  authDomain: "resume-f792d.firebaseapp.com",
  projectId: "resume-f792d",
  storageBucket: "resume-f792d.appspot.com",
  messagingSenderId: "377192921313",
  appId: "1:377192921313:web:89cd158ab6d1dfafc11fa0",
  measurementId: "G-QCHHG1KG7S",
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function usePreventZoom(active = false, scrollCheck = true, keyboardCheck = true) {
  useEffect(() => {
    if (active) {
      const handleKeydown = (e) => {
        if (
          keyboardCheck &&
          (e.metaKey || e.ctrlKey) &&
          (e.keyCode === 61 ||
            e.keyCode === 107 ||
            e.keyCode === 173 ||
            e.keyCode === 109 ||
            e.keyCode === 187 ||
            e.keyCode === 189)
        ) {
          e.preventDefault();
        }
      };

      const handleWheel = (e) => {
        if (scrollCheck && e.ctrlKey) {
          e.preventDefault();
        }
      };

      document.addEventListener("keydown", handleKeydown);
      document.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        document.removeEventListener("keydown", handleKeydown);
        document.removeEventListener("wheel", handleWheel);
      };
    }
  }, [scrollCheck, keyboardCheck, active]);
}

function Header() {
  return (
    <>
      <div className="header">
        <div className="logoType">
          <div className="logo">a</div>
          <div>
            <div className="titleText">{resume.header.name}</div>
            <div>
              <h2>
                {resume.header.tag}{" "}
                <a href={"https://" + resume.header.url} target="_blank" rel="noreferrer">
                  {resume.header.url}
                </a>
              </h2>
            </div>
          </div>
        </div>
        <div className="contact">
          <div>
            <h5>{resume.header.city}</h5>
          </div>
          <div>
            <h5>{resume.header.phone}</h5>
          </div>
          <div>
            <h5>
              <a href={"mailto:" + resume.header.email}>{resume.header.email}</a>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

function Intro() {
  return (
    <div className="intro">
      <div>
        <h2>{resume.intro.tagline}</h2>
      </div>
      <div>
        <h2>{resume.intro.description}</h2>
      </div>
    </div>
  );
}

function Work() {
  const workList = resume.work.map((item, key) => (
    <div key={key}>
      <div className="twoColumn">
        <div className="smallColumn">
          <div className="textBlock">
            <h3> {item.entry.role} </h3>
          </div>
          <div className="textBlock">
            <a href={item.entry.url} target="_blank" rel="noreferrer">
              {item.entry.company}
            </a>
          </div>
          <div className="textBlock">{item.entry.industry}</div>
          <div className="textBlock">
            <h6>{item.entry.date}</h6>
          </div>
        </div>
        <div className="fillColumn">
          {item.entry.highlights.map((subItem, subKey) => (
            <div className="listItem" key={subKey}>
              <Markdown>{subItem}</Markdown>
              <Spacer space={subKey < item.entry.highlights.length - 1 ? "10px" : "0px"} />
            </div>
          ))}
        </div>
      </div>
      <Spacer space={key < resume.work.length - 1 ? "18px" : "0px"} />
    </div>
  ));

  return (
    <div className="section" id="work">
      <div className="subSection">
        <h1>WORK</h1>
      </div>
      <div className="subSection">{workList}</div>
      <Spacer space={"10px"} />

      <div className="subSection">
        <h6>... Jan 2008 — early full-time career</h6>
      </div>
    </div>
  );
}

function Study() {
  const studyList = resume.study.map((item, key) => (
    <div key={key}>
      <div className="twoColumn">
        <div className="smallColumn">
          <div>
            <h3>{item.entry.degree} </h3>
          </div>
          <div>
            <a href={item.entry.url} target="_blank" rel="noreferrer">
              {item.entry.institution}
            </a>
          </div>
          <div> {item.entry.industry} </div>
          <div>
            <h6>{item.entry.date}</h6>
          </div>
        </div>
        <div className="fillColumn">
          {item.entry.highlights.map((subItem, subKey) => (
            <div className="listItem" key={subKey}>
              <Markdown>{subItem}</Markdown>
              <Spacer space={subKey < item.entry.highlights.length - 1 ? "10px" : "0px"} />
            </div>
          ))}
        </div>
      </div>
      <Spacer space={key < resume.study.length - 1 ? "25px" : "0px"} />
    </div>
  ));

  return (
    <>
      <div className="spacer"></div>
      <div className="section" id="study">
        <div className="subSection">
          <h1>STUDY</h1>
        </div>
        <div className="subSection">{studyList}</div>
      </div>
    </>
  );
}

function Skills() {
  const skillsDescription = resume.skills[0].description;
  let skillsColumnOneData = [];
  let skillsColumnTwoData = [];

  for (let index = 1; index < resume.skills.length - skillsColumnOneData.length; index++) {
    skillsColumnOneData.push(resume.skills[index]);
  }
  for (let index = skillsColumnOneData.length + 1; index < resume.skills.length; index++) {
    skillsColumnTwoData.push(resume.skills[index]);
  }

  function Symbol(i) {
    const number = 9625 + i;
    return String.fromCharCode(number);
  }

  const skillsColumnOne = skillsColumnOneData.map((item, key) => (
    <div key={key} className="skillBlock">
      <div className="skillTitle">
        <span className="skillSymbol">
          <h5>{Symbol(key) + Symbol(key + 2)}</h5>
        </span>
        <span>
          <h3>
            <Markdown>{item.entry.skill}</Markdown>
          </h3>
        </span>
      </div>
      <div>
        {item.entry.highlights.map((subItem, subKey) => (
          <div key={subKey} className="listItem">
            <div>
              <Markdown>{subItem}</Markdown>
              <Spacer space={subKey < item.entry.highlights.length - 1 ? "5px" : "0px"} />
            </div>
          </div>
        ))}
      </div>
      <Spacer space={key < skillsColumnOneData.length - 1 ? "15px" : "0px"} />
    </div>
  ));

  const skillsColumnTwo = skillsColumnTwoData.map((item, key) => (
    <div key={key} className="skillBlock">
      <div className="skillTitle">
        <span className="skillSymbol">
          <h5>{Symbol(skillsColumnOneData.length + key) + Symbol(skillsColumnOneData.length + key + 2)}</h5>
        </span>
        <span>
          <h3>
            <Markdown>{item.entry.skill}</Markdown>
          </h3>
        </span>
      </div>
      <div>
        {item.entry.highlights.map((subItem, subKey) => (
          <div key={subKey} className="listItem">
            <div>
              <Markdown>{subItem}</Markdown>{" "}
              <Spacer space={subKey < item.entry.highlights.length - 1 ? "5px" : "0px"} />
            </div>
          </div>
        ))}
      </div>
      <Spacer space={key < skillsColumnTwoData.length - 1 ? "15px" : "0px"} />
    </div>
  ));

  return (
    <>
      {/* <div className="spacer"></div> */}
      <div className="section" id="skills">
        <div className="subSection">
          <h1>SKILLS</h1>
        </div>
        <div className="subSection">
          <div className="largeColumn">
            <div className="listItem">
              <Markdown>{skillsDescription[0]}</Markdown>
            </div>
            <Spacer space={"5px"} />
            <div className="listItem">
              <Markdown>{skillsDescription[1]}</Markdown>
            </div>
          </div>
        </div>
        {/* <div className="spacer" /> */}
        <div className="subSection">
          <Spacer space={"15px"} />
          <div className="twoColumn">
            <div className="equalColumn">{skillsColumnOne}</div>
            <div className="fillColumn">{skillsColumnTwo}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function ClassesWorkshops() {
  const classesWorkshopsList = resume.classesWorkshops.map((item, key) => (
    <div key={key}>
      <div className="listItem">
        <div>
          <span className="date">{item.entry.date + " • "}</span>
          <b>
            <a href={item.entry.url} target="_blank" rel="noreferrer">
              {item.entry.school}
            </a>
            {" – "}
            <i>{item.entry.class}</i>
          </b>
        </div>
        <div>
          <h6>{item.entry.place}</h6>
        </div>
      </div>
      <Spacer space={key < resume.classesWorkshops.length - 1 ? "5px" : "0px"} />
    </div>
  ));
  return (
    <>
      <div className="spacer"></div>
      <div className="section" id="classesWorkshops">
        <div className="subSection">
          <h1>CLASSES AND WORKSHOPS GIVEN</h1>
        </div>
        <div className="subSection">
          <div>{classesWorkshopsList}</div>
        </div>
      </div>
    </>
  );
}

function HonorsAwards() {
  const honorsAwardsList = resume.honorsAwards.map((item, key) => (
    <div key={key}>
      <div className="listItem">
        <div>
          <b>
            {item.entry.award + " — "}
            {item.entry.url ? (
              <a href={item.entry.url} target="_blank" rel="noreferrer">
                {item.entry.competition}
              </a>
            ) : (
              item.entry.competition
            )}
          </b>
        </div>
        <div>
          <h6>{item.entry.project}</h6>
        </div>
      </div>
      <Spacer space={key < resume.honorsAwards.length - 1 ? "5px" : "0px"} />
    </div>
  ));

  return (
    <>
      <div className="spacer"></div>
      <div className="section" id="honorsAwards">
        <div className="subSection">
          <h1>HONORS & AWARDS</h1>
        </div>
        <div className="subSection">
          <div>{honorsAwardsList}</div>
        </div>
      </div>
    </>
  );
}

function Media() {
  const televisionList = resume.mediaTelevision.map((item, key) => (
    <div key={key} className="listItem">
      <div>
        <span className="date">{item.entry.date + " • "}</span>
        <b>{item.entry.channel + ": "}</b>
        <a href={item.entry.url} target="_blank" rel="noreferrer">
          {item.entry.media}
        </a>
      </div>
      <div className="midColumn">
        <h6>{item.entry.highlights}</h6>
        <Spacer space={key < resume.mediaTelevision.length - 1 ? "5px" : "0px"} />
      </div>
    </div>
  ));

  const podcastList = resume.mediaPodcast.map((item, key) => (
    <div key={key} className="listItem">
      <div>
        <span className="date">{item.entry.date + " • "}</span>
        <b>{item.entry.channel + ": "}</b>
        <a href={item.entry.url} target="_blank" rel="noreferrer">
          {item.entry.media}
        </a>
      </div>
      <div>{item.entry.highlights}</div>
      <Spacer space={key < resume.mediaPodcast.length - 1 ? "5px" : "0px"} />
    </div>
  ));

  const digitalMagazineList = resume.mediaDigitalMagazine.map((item, key) => (
    <div key={key}>
      <div className="listItem">
        <div>
          <span className="date">{item.entry.date + " • "}</span>
          <b>{item.entry.channel + ": "}</b>
          <a href={item.entry.url} target="_blank" rel="noreferrer">
            {item.entry.media}
          </a>
        </div>
        <div>{item.entry.highlights}</div>
      </div>
      <Spacer space={key < resume.mediaDigitalMagazine.length - 1 ? "5px" : "0px"} />
    </div>
  ));

  const printedMagazineList = resume.mediaPrintedMagazine.map((item, key) => (
    <div key={key}>
      <div className="listItem">
        <div>
          <span className="date">{item.entry.date + " • "}</span>
          <b>{item.entry.channel + ": "}</b>
          <a href={item.entry.url} target="_blank" rel="noreferrer">
            {item.entry.media}
          </a>
        </div>
        <div>{item.entry.highlights}</div>
      </div>
      <Spacer space={key < resume.mediaPrintedMagazine.length - 1 ? "5px" : "0px"} />
    </div>
  ));

  return (
    <>
      {/* <div className="spacer" /> */}
      <div className="section" id="media">
        <div className="subSection">
          <h1>MEDIA</h1>
        </div>
        <div className="subSection">
          <div>
            <div className="subTitleText">
              <h3>TELEVISION</h3>
            </div>
            <div>{televisionList}</div>
            <Spacer space={"10px"} />
          </div>
          <div>
            <div className="subTitleText">
              <h3>PODCAST</h3>
            </div>
            <div>{podcastList}</div>
            <Spacer space={"10px"} />
          </div>
          <div>
            <div className="subTitleText">
              <h3>DIGITAL MAGAZINE</h3>
            </div>
            <div>{digitalMagazineList}</div>
            <Spacer space={"10px"} />
          </div>
          <div>
            <div className="subTitleText">
              <h3>PRINTED MAGAZINE</h3>
            </div>
            <div>{printedMagazineList}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function LecturesProfessional() {
  const lectureList = resume.lecturesProfessional.map((item, key) => (
    <div key={key}>
      <div className="listItem">
        <div>
          <span className="date">{item.entry.date + " • "}</span>
          <b>
            {item.entry.event} —{" "}
            {item.entry.url ? (
              <a href={item.entry.url}>
                <i>{item.entry.lecture}</i>
              </a>
            ) : (
              <i>{item.entry.lecture}</i>
            )}
          </b>
        </div>
        <div>
          <h6>
            {item.entry.place} {item.entry.with ? " — with " + item.entry.with : null}
          </h6>
        </div>
      </div>
      <Spacer space={key < resume.lecturesProfessional.length - 1 ? "4.5px" : "0px"} />
    </div>
  ));

  return (
    <>
      {/* <div className="spacer"></div> */}
      <div className="section" id="lecturesProfessional">
        <div className="subSection">
          <h1>LECTURES – PROFESSIONAL ENVIRONMENT</h1>
        </div>
        <div className="subSection">{lectureList}</div>
      </div>
    </>
  );
}

function LecturesAcademic() {
  const lectureList = resume.lecturesAcademic.map((item, key) => (
    <div key={key}>
      <div className="listItem">
        <div>
          <span className="date">{item.entry.date + " • "}</span>
          <b>
            {item.entry.event} —{" "}
            {item.entry.url ? (
              <a href={item.entry.url}>
                <i>{item.entry.lecture}</i>
              </a>
            ) : (
              <i>{item.entry.lecture}</i>
            )}
          </b>
        </div>
        <div>
          <h6>
            {item.entry.place} {item.entry.with ? " — with " + item.entry.with : null}
          </h6>
        </div>
      </div>
      <Spacer space={key < resume.lecturesAcademic.length - 1 ? "2px" : "0px"} />
    </div>
  ));

  return (
    <>
      <div className="spacer"></div>
      <div className="section" id="lecturesAcademic">
        <div className="subSection">
          <h1>LECTURES – ACADEMIC ENVIRONMENT</h1>
        </div>
        <div className="subSection">{lectureList}</div>
      </div>
    </>
  );
}

function Footer() {
  return (
    <div className="footer">
      <div className="pageMarker"></div>
    </div>
  );
}

function BigLogo() {
  return (
    <div className="bigLogo">
      <div className="socialIcons">
        <div className="socialIcons">
          <a href="https://github.com/andreisperid/">
            <Icon icon="mdi:github" />
          </a>
        </div>
        <div className="socialIcons">
          <a href="https://www.linkedin.com/in/andreisperid/">
            <Icon icon="mdi:linkedin" />
          </a>
        </div>
        <div className="socialIcons">
          <a href="https://www.instagram.com/andreisperid/">
            <Icon icon="mdi:instagram" />
          </a>
        </div>
        <div className="socialIcons">
          <a href="https://www.flickr.com/photos/andreisperid/albums">
            <Icon icon="typcn:social-flickr" />
          </a>
        </div>
      </div>
      <div className="socialIcons">
        <a href="https://andrei.cc" style={{ textDecoration: "none", fontSize: "20px" }}>
          andrei.cc
        </a>
      </div>
    </div>
  );
}

function defaultZoom(zoom = false) {
  const sheetComponent = document.getElementsByClassName("sheet")[0];

  let heightScaleRatio = window.innerHeight / sheetComponent.offsetHeight;
  let widthScaleRatio = (window.innerWidth * 0.85) / sheetComponent.offsetWidth;

  let zoomLevel;

  if (window.innerWidth / window.innerHeight > 1) {
    zoomLevel = zoom ? widthScaleRatio : heightScaleRatio;
    document.getElementsByClassName("controls")[0].style.visibility = "visible";
    document.documentElement.style.scrollSnapType = zoom ? "unset" : "y mandatory";
  } else {
    zoomLevel = (window.innerWidth * 0.975) / sheetComponent.offsetWidth;
    document.getElementsByClassName("controls")[0].style.transition = "0s";
    document.getElementsByClassName("controls")[0].style.visibility = "hidden";

    document.documentElement.style.scrollSnapType = "unset";
  }

  document.getElementById("printable").style.zoom = zoomLevel;
  // document.getElementById("printable").style.transform = `scale(${zoomLevel})`;
}

function PrintButton() {
  const [hovered, setHover] = useState(false);

  const handleClick = () => {
    document.getElementsByClassName("controls")[0].style.opacity = 0;
    document.getElementsByClassName("background")[0].style.zIndex = 999;
    document.getElementsByClassName("background")[0].style.opacity = 0.75;

    setHover(false);
    document.getElementById("printable").style.zoom = 1;
    // document.getElementById("printable").style.transform = `scale(1)`;

    window.print();
  };

  useEffect(() => {
    window.addEventListener("focus", function () {
      document.getElementsByClassName("controls")[0].style.opacity = 1;
      document.getElementsByClassName("background")[0].style.zIndex = 0;
      document.getElementsByClassName("background")[0].style.opacity = 1;
    });
  });

  return (
    <div className="buttonSet">
      <div
        className="button"
        onMouseDown={(e) => {
          handleClick();
          logEvent(analytics, "print");
          e.stopPropagation();
        }}
        onPointerOver={(e) => {
          setHover(true);
          e.stopPropagation();
        }}
        onPointerOut={(e) => {
          setHover(false);
          e.stopPropagation();
        }}
      >
        <Icon icon="material-symbols:print-outline-rounded" />
      </div>
      <div className="buttonLabel" style={{ visibility: hovered ? "visible" : "hidden" }}>
        Print
      </div>
    </div>
  );
}

function ToggleZoomButton() {
  const [hovered, setHover] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [prevState, setPrevState] = useState(false);
  const [ratio, setRatioState] = useState(0);

  useEffect(() => {
    window.addEventListener("focus", function () {
      defaultZoom(zoom);
    });

    window.addEventListener("resize", function () {
      defaultZoom(zoom);
    });

    defaultZoom(zoom);

    window.addEventListener("scroll", function () {
      setRatioState(window.scrollY / document.body.scrollHeight);
    });

    if (zoom !== prevState) {
      window.scroll(0, document.body.scrollHeight * ratio);
      setPrevState(!prevState);
    }
  }, [zoom, prevState, ratio]);

  return (
    <div className="buttonSet">
      <div
        className="button"
        onMouseDown={(e) => {
          setZoom(!zoom);
          logEvent(analytics, "zoom");
          e.stopPropagation();
        }}
        onPointerOver={(e) => {
          setHover(true);
          e.stopPropagation();
        }}
        onPointerOut={(e) => {
          setHover(false);
          e.stopPropagation();
        }}
      >
        {zoom ? <Icon icon="material-symbols:zoom-out-rounded" /> : <Icon icon="material-symbols:zoom-in-rounded" />}
      </div>
      <div className="buttonLabel" style={{ visibility: hovered ? "visible" : "hidden" }}>
        {zoom ? "Reset" : "Fit"}
      </div>
    </div>
  );
}

function CurrentPage() {
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    if (!totalPages) setTotalPages(document.getElementById("printable").children.length);

    const updateScroll = () => {
      const tempPage = Math.ceil(
        ((window.scrollY + window.innerHeight / 2) / document.documentElement.scrollHeight) * totalPages
      );
      if (tempPage !== currentPage) {
        setCurrentPage(tempPage);
      }
    };

    if (!currentPage) updateScroll();
    window.addEventListener("scroll", updateScroll);

    document.getElementsByClassName("controls")[0].style.opacity = 1;

    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, [currentPage, totalPages]);

  return <div className="pageCount" id="pageCount">{`${currentPage} of ${totalPages}`}</div>;
}

function Spacer({ space }) {
  return <div className="spacer" style={{ height: space }}></div>;
}

function App() {
  usePreventZoom(window.innerWidth / window.innerHeight > 1);

  logEvent(analytics, "open");

  window.addEventListener("load", function () {
    defaultZoom();
  });

  return (
    <div className="App">
      <div className="background" />

      <div className="controls">
        <CurrentPage />
        <ToggleZoomButton />
        <PrintButton />
      </div>

      <header className="App-header" id="printable">
        <div className="segment">
          <div className="sheet">
            <div className="page">
              <Header />
              <Intro />
              <Work />
              <Study />
            </div>
            <Footer />
          </div>
        </div>

        <div className="segment">
          <div className="sheet">
            <div className="page">
              <Header />
              <Skills />
              <HonorsAwards />
              <ClassesWorkshops />
            </div>
            <Footer />
          </div>
        </div>

        <div className="segment">
          <div className="sheet">
            <div className="page">
              <Header />
              <Media />
              <LecturesAcademic />
            </div>
            <Footer />
          </div>
        </div>

        <div className="segment">
          <div className="sheet">
            <div className="page">
              <Header />
              <LecturesProfessional />
              <BigLogo />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
